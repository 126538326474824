import dominika from '../assets/RCRT8.jpg';

function AboutMe() {
  return (
    <div className='flex flex-col justify-center items-center sm:items-start sm:flex-row mx-auto w-full sm:w-1/2 gap-4'>
      <div className='w-1/3 sm:w-full flex justify-end'>
        <img src={dominika} alt='dominika' className='rounded-xl' />
      </div>
      <div className='w-full text-base text-slate-500 flex flex-col gap-4'>
        <div className=' '>
          My name is Dominika, and I'm a Certified Canadian Reflexology
          Therapist specializing in Foot and Hand therapy accredited by the
          Reflexology Association of Canada (RAC). With over 11 years of
          experience, I offer both Reflexology and Raindrop Therapy Massage.
          Since June 2023, I've expanded my expertise to include Hadado, an
          enhanced version of the traditional "Kobido" Japanese Face Lifting
          Massage and Buccal massage. I provide services in Mississauga and
          nearby areas.
        </div>
        <div className=''>
          For your convenience, I offer mobile/in-home sessions, bringing
          Foot/Hand Reflexology, Raindrop Therapy Massage, and Hadado "Kobido"
          Japanese Face Lifting Massage and Buccal treatments to your doorstep.
          This eliminates the hassle of travel, allowing you to fully relax in
          your own space post-treatment, maximizing the benefits.
        </div>
        <div className=''>
          In addition, I provide spa sessions in Mississauga, Oakville, and
          Waterloo, Ontario. Enjoy Foot/Hand Reflexology, Raindrop Therapy
          Massage, and Hadado "Kobido" Japanese Face Lifting Massage in a serene
          spa environment.
        </div>
        <div className=''>
          I, Dominika have an extensive training through the following courses
          and workshops. I am committed to a life long learning and I am
          continually educating myself.
        </div>
        My formal training consists of the following:
        <div>
          <ul className='list-disc pl-8 space-y-4'>
            <li>
              Registered Canadian Reflexology Therapist in Foot
              Reflexology,Training by: Mary Jardin, Certified Reflexology
              Teacher in Foot, Hand and Ear, LCRT. Oshawa, Ontario. March, 2013.
            </li>
            <li>
              Certified through and a memeber of Reflexology Association of
              Canada, Winnipeg, MB, 2014.
            </li>
            <li>
              Certificate of Completion in Raindrop Therapy Massage Workshop by
              Serenity Place Holistic Therapies and Learning Centre in Fenelon
              Falls, Ontario. Instructor: Ruth Phillips. May 4, 2014.
            </li>
            <li>
              Certificate of Completion in Jin Shin Jyutsu - Self help Book 1,
              by Serenity Place Holistic Therapies and Learning Centre in
              Fenelon Falls, Ontario. Instructor: Ruth Phillips. September 6,
              2014.
            </li>
            <li>
              How to Relieve CHRONIC FOOT PAIN, Academy of Ancient Reflexology.
              Training by: Karen Ball, Certified Reflexology Teacher. Lindsay,
              Ontario. September 19, 2015.
            </li>
            <li>
              Certificate of Complition in The Bach Flower Remedies, Instructor:
              Karen Christopher, Peterborough, Ontario. April 2016.
            </li>
            <li>
              Registered in Hand Reflexology, Training by: Mary Jardin,
              Certified Reflexology Teacher in Foot, Hand and Ear, LCRT. Oshawa,
              Ontario. September, 29, 2017.
            </li>
            <li>
              Reflexology RLD and Immune Health, Certificate of Attendance;
              Attended Reflexology, RLD (Reflexology Lymph Drainage) and Immune
              Health training, understanding inflammation and autoimmune
              disorders. Instructor: Sally Kay, Ottawa, Ontario, May 5th, 2023.
            </li>
            <li>
              Certified Hadado (Kobido) Japanese Lifting Face Massage,
              Instructor: Tomasz Tomasik, Toronto, Ontario, June 13-15, 2023.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
