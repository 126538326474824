import { Link } from 'react-router-dom';

const NavBar = ({ setIsNavBarOpen }) => {
  return (
    <div className='flex justify-between items-center text-lg text-white absolute top-0 right-0 sm:top-auto sm:right-auto sm:relative  w-3/4 sm:w-full  sm:bg-transparent z-0 sm:z-10'>
      <div className='flex flex-row items-center bg-black w-full h-full'>
        <div className='hidden sm:block p-4 text-base sm:text-lg w-1/2'>
          <Link to='/'>Ontario Reflexology</Link>
        </div>

        <div className='flex flex-col p-4 sm:p-0 items-start sm:justify-end sm:gap-4 sm:flex-row sm:items-center h-full w-full'>
          <Link to='/' onClick={() => setIsNavBarOpen(false)}>
            <div className=' py-2 sm:p-4 hover:cursor-pointer hover:text-gray-400'>
              Home
            </div>
          </Link>
          <Link to='/services' onClick={() => setIsNavBarOpen(false)}>
            <div className=' py-2 sm:p-4 hover:cursor-pointer hover:text-gray-400'>
              Services
            </div>
          </Link>
          <Link to='/testimonials' onClick={() => setIsNavBarOpen(false)}>
            <div className='py-2 sm:p-4 hover:cursor-pointer hover:text-gray-400'>
              Testimonials
            </div>
          </Link>
          <Link to='/contact' onClick={() => setIsNavBarOpen(false)}>
            <div className='py-2 sm:p-4 hover:cursor-pointer hover:text-gray-400'>
              Contact
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
