import dominika from '../assets/RCRT7.jpg';

function AboutMe() {
  return (
    <div className='flex flex-col-reverse justify-center items-center sm:items-start sm:flex-row mx-auto w-full sm:w-1/2 gap-4'>
      <div className='w-full text-base text-slate-500 flex flex-col gap-4'>
        <div className='text-lg font-bold text-slate-600'>
          Mobile Services and in Spa Days
        </div>
        Providing Mobile Reflexology/In-Home sessions three days a week,
        available from 10 am to 7 pm, right in the comfort of your own home.
        Serving Mississauga and its neighboring areas. Additionally, offering
        spa sessions from Monday to Thursday.
      
        <div className='my-4'>
          <div className='text-lg font-bold text-slate-600'>Waterloo</div>
          <div className=' '>Jolanta's European Spa</div>
          <div className='my-2'>
            <div className=' '>Tuesday 9:00 am to 4:00 pm</div>
            <div className=' '>Wednesday 10:00 am to 6:00 pm</div>
            <div className=' '>Thursday 10:00 am to 3:00 pm</div>
          </div>
          <div className=' '>125 Park St, Waterloo, On, N2L 1Y4</div>
        </div>
        <div className='my-4'>
          <div className='text-lg font-bold text-slate-600'>Oakville</div>
          <div className=' '>Monterella Boutique & Spa</div>
          <div className='my-2'>Thursday 10:00 am to 6:00 pm</div>
          <div className=' '>92d Dunn St, Oakville, ON, L6J 3C7</div>
        </div>
        <div className='text-lg font-bold text-slate-600'>705.344.5792</div>
        dominikareflexology@gmail.com
      </div>
      <div className='w-1/3 sm:w-full flex justify-end'>
        <img src={dominika} alt='dominika' className='rounded-xl' />
      </div>
    </div>
  );
}

export default AboutMe;
