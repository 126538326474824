function Testimonials() {
  return (
    <div className='flex flex-col-reverse justify-center items-center sm:items-start sm:flex-row mx-auto w-full sm:w-1/2 gap-4'>
      <div className='w-full text-base text-slate-500 flex flex-col gap-4'>
        <div className='text-base'>
          "Exceptional Reflexology Experience! A huge thank you to Dominika –
          from start to finish the level of expertise and professionalism was
          exceptional. I suffered from barometric pressure headaches. For those
          who know/experience this, you know the pain and struggle. After just
          one session with Dominika the profound difference and the sense of
          relief I felt was just amazing. Her skilled hands worked magic and
          targeted key pressure points with such precision. Thank you! I highly
          recommend everyone book an appointment and experience the impact
          reflexology sessions with Dominika will make in your life."
        </div>
        <div className='text-xl'>Dimitrije, Waterloo, ON</div>
        <hr />
        <div className='text-base'>
          "I have been a client for over four years, for both Reflexology and
          Raindrop Therapy Massage. Dominika is a gifted practitioner. She
          offers insight and shares expert knowledge as needed with her clients.
          She goes above and beyond to deliver relaxing and therapeutic
          treatments, I highly recommend her services."
        </div>
        <div className='text-xl'>Cynthia Sneath, Fenelon Falls, ON</div>
        <hr />
        <div className='text-base'>
          "In writing my review of Dominika Gulanowska in regards to both her
          Reflexology Treatments and also her Raindrop Therapy Massage
          Treatments I have to pause and gather my thoughts. There are so many
          positive aspects to comment on. Upon entering Dominika's Treatment
          Room as a client I was overwhelmed by both her welcoming smile and the
          ambiance of her Healing Room. She was professional and forthcoming in
          any discoveries she made during my reflexology treatment. After so
          many visits for reflexology I decided to try her Raindrop Therapy
          Massage. What a beautiful experience and one I definitely returned to
          time and time again. I referred other friends to Dominika for both
          treatments and always I was thanked for sharing Dominika's skills.
          Some friends drove two-three hours to have a treatment. With little
          hesitation I asked Dominika to join my Peace By The Water Retreats
          where we provide healing on many levels to Women for weekends. Here
          Dominika offered both her Reflexology and Raindrop Therapy Massage
          Treatments. Every client was glowing after they left her room, many
          asked for Dominika's contact information to share with others. Those
          clients who are fortunate to have the opportunity to experience
          Dominika's treatments will truly find a practitioner who is
          respectful, professional, kind and talented!"
        </div>
        <div>
          <div className='text-xl'>Louise Spittal, Fenelon Falls, ON</div>
          <div>
            'If light is in your heart,you will find your way home' - Rumi
          </div>
          <div>Peace By The Water</div>
          www.peacebythewaterretreat.com
        </div>
        <hr />
        <div className='text-base'>
          "The first time I received a foot reflexology treatment from Dominika
          I remember walking to my car feeling like I was almost floating. All
          my stress had dissipated and I physically felt lighter, like weight
          had literally been lifted from my body. Aside from how well it helps
          with stress, sometimes my bowels do not function as optimally as I
          would like and when this happens all it takes is one treatment from
          her and I am completely back on track. She is an amazing reflexology
          practitioner and she has this wonderful, compassionate, calming energy
          about her! And I would absolutely recommend her to everyone!"
        </div>
        <div className='text-xl'>Amanda Chandler, Fenelon Falls, ON</div>
        <hr />
        <div className='text-base'>
          "I was delighted to receive a Reflexology treatment and a Raindrop
          Therapy Massage from Dominika Gulanowska. During my Reflexology
          treatments, I loved Dominika's warm hands on my feet. Her pressure was
          just right and firm. After the session, I felt totally rejuvenated and
          my feet and whole body felt like new. The pains I felt in my body and
          feet disappeared. When I started with Dominika, I felt heavy in my
          body and after the session, I felt very light, like I was walking on
          the clouds. I truly feel Dominika has a gift. Her positive energy and
          her firm, warm hands feel like heaven. Recently, I had a pleasure to
          try a Raindrop Therapy Massage. During the treatment, I felt like I
          was floating twenty centimetres above my body. I felt extremely
          relaxed as if all my stresses, physical and emotional melted away.
          Dominika's hands felt wonderful. She released all my neck, shoulder
          and lower back tension. After she worked all the oils into my back,
          she placed a hot towel along my spine covering my full back. I
          literally felt like I was at the tropical island. It felt so good.
          After this treatment, I felt extremely relaxed, very calm and in the
          state of deep peace and joy. The next day after the Raindrop Therapy,
          I felt very good. I slept very well. I had no pain along my whole
          spine. Usually, when I get up from the bed first thing in the morning,
          I feel very stiff and in pain especially in the lower back. To my
          surprise, I had no pains and felt very flexible. Also, I felt very
          optimistic and my mood was great, felt blessed and full of joy and
          happiness. Also, I felt a lot of energy in my body, like I was ready
          to go. I feel the Raindrop Therapy Massage was absolutely wonderful
          and I keep spreading the word as I feel everyone should try it to see
          how heavenly it feels. I highly recommend Dominika as she has not only
          melted all my stresses and pains away but her beautiful energy and
          talented hands are something everyone should experience. She is a true
          gift and I highly recommend her to everyone."
        </div>
        <div className='text-xl'>Malgorzata Kolodziejczyk, Mississauga, ON</div>
        <hr />
        <div className='text-base'>
          "If you are seeking a super relaxing, very healing experience with a
          pleasant, highly skilled practitioner, then Dominika is your girl. She
          is quite amazing!"
        </div>
        <div className='text-xl'>Dorothy Snell, Fenelon Falls, ON</div>
      </div>
    </div>
  );
}

export default Testimonials;
