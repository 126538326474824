function Services() {
  return (
    <div className='flex flex-col justify-center items-center sm:items-start sm:flex-row mx-auto w-full sm:w-1/2 gap-4'>
      <div className='w-full text-base text-slate-500 flex flex-col gap-4'>
        <div className='text-center w-full text-lg text-black flex flex-col gap-4'>
          Hadado Japanese Face Lifting Massage
        </div>
        <div className='text-black'>Characteristics of Hadado Massage</div>
        <div>
          Most facial massage techniques work on the surface of the face.
          Hadada, due to its complexity and intensity of techniques, works on
          the deeper layers of the skin and muscles. This face lifting massage
          is the most technically advanced form of facial massage in Japan, if
          not the world. Hadada has the largest number and the most complex set
          of manual techniques. The massage is a deep combination of traditional
          Japanese medical concepts and various hand manipulation techniques. It
          is mainly characterized by the speed of the hands and fingers. The
          speed with which I perform the massage and all the steps tends to work
          in different aspects. Thus, when the movement is slow, the client is
          supposed to relax, and the intensive massage stages are supposed to
          stimulate the skin and the production of skin ingredients in order to
          achieve the expected lifting effect. The purpose of Japanese facial
          massage and the techniques performed is to accurately and precisely
          determine the condition of the skin and work on acupressure points to
          achieve balance in the facial skin, facial muscles and related body.
          For example, the vibration technique, which is applied with rapid
          shaking of the hands and fingers and is commonly used to reduce muscle
          tension and quickly release toxins from the lymphatic system. The
          massage activates the facial nerves and thus increases the flow of Qi
          (life energy) on the face, which leads to a natural lifting effect.
        </div>
        <div className='text-black'>
          Description of the Hadado (Kobido) face lifting massage session.
        </div>
        <div>
          Hadado session begins in the sitting position. The session starts by
          me striping the neck and back muscles using deep tissue massage
          techniques. Next, the client moves onto the massage table laying on
          his/her back. After that, I move into the smooth and delicate
          movements that aim to relax the client and eliminate tension in the
          face, neck and chest muscles. When the skin tissue feels right, I move
          onto the neck massage elements to activate the blood circulation that
          carries the ingredients to the facial skin to achieve tissue
          improvement and repair. Then, I move onto the most characteristic and
          intensive techniques for Kobido massage; percussion, quick pinching,
          gentle strokes, kneading and deep smoothing of the skin. The goal of
          intensive techniques is to remove any tension that tends to freeze the
          muscles and their function. Lifting techniques are mainly performed on
          the face, but can also be used on the other parts of the body, such as
          the neck, décolleté and arms. VISUAL EFFECTS OF KOBIDO massage is
          known as a "NON-SURGICAL FACE LIFT". Thanks to the techniques used,
          you may naturally and over time;
        </div>
        <div>
          <ul className='list-disc pl-8 space-y-4'>
            <li>Eliminate muscle tension that causes mimic wrinkles</li>
            <li>Prevent or reduce the effects of skin aging</li>
            <li>Regain a young face through deep work on muscle structures</li>
            <li>
              Stimulate cell metabolism, which will contribute to faster skin
              regeneration
            </li>
            <li>
              Cleanse the skin by removing toxins, impurities and dead skin
              cells, thus adding a natural glow to it
            </li>
            <li>Eliminate excessive tension around the neck, chest and face</li>
            <li>
              Stimulate the production of skin components (collagen and elastin)
            </li>
            <li>Regulate the energy of the whole body</li>
            <li>Fight stress</li>
            <li>Reduce the effects of bruxism</li>
            <li>Relieve headaches</li>
            <li>Might avoid the effects of facial stiffness</li>
            <li>Reduce problems with the digestive system</li>
            <li>Improve eyesight</li>
          </ul>
        </div>
        <div className='text-black'>Why Hadado massage?</div>
        <div>
          The Hadado Massage is a great option for those who want a total
          relaxing session combined with a spectacular face lift effect. This
          type of massage of Japanese origin is also ideal for those who suffer
          from migraines, providing rest and relaxation in the mind, as well as
          for people with “heavy eyes” spending many hours in front of the
          computer, because it additionally relaxes the muscles around the eyes.
          Hadado is also beneficial for those who suffer from bruxism as it
          reduces the effects of tension in the maxillofacial area.
        </div>

        <hr />

        <div className='text-center w-full text-lg text-black flex flex-col gap-4'>
          Buccal Massage
        </div>
        <div>
          Buccal massage (also called intraoral massage) involves massaging
          pressure points inside the mouth to stimulate the muscles and
          ligaments in the buccal fat pad (between the cheeks and the jowls) to
          tighten and contour the area. When done regularly, this procedure can
          provide similar results to those of dermal fillers, but without the
          needles and potential side effects. The treatment typically involves
          massage of other facial areas as well.
        </div>
        <div>
          When facial muscles stiffen due to stress, injury, an overactive jaw,
          or a medical disorder, they obstruct blood flow, lymphatic
          circulation, and detoxication. Tissues don’t receive enough nutrients
          and oxygen, resulting in water retention, puffiness, a dull
          complexion, and drooping cheeks and jowls. Buccal massage resolves
          these issues, providing numerous skin benefits.
        </div>

        <div className='text-black'>Buccal Massage Benefits:</div>

        <div>
          <ul className='list-disc pl-8 space-y-4'>
            <li>Improve blood circulation</li>
            <li>Excess fluid removal and reduced puffiness</li>
            <li>Tighter skin in the lower face</li>
            <li>Rejuvenated (more youthful) appearance</li>
            <li>Clearer skin and reduced acne</li>
            <li>Decreased tension in the lower face</li>
            <li>
              Reduced chronic pain, eases related symptoms such as headaches and
              facial pain and helpful aid in managing TMJ “temporomandibular
              joint” disorders
            </li>
            <li>Reduces sinus discomfort</li>
          </ul>
        </div>

        <hr />

        <div className='text-center w-full text-lg text-black flex flex-col gap-4'>
          Foot and Hand Reflexology
        </div>
        <div className='text-black'>Reflexology</div>
        <div>
          Reflexology is the science that deals with the principle that there
          are reflex areas in the feet and hands that correspond to all the
          glands, organs and parts of the body. Reflexology is NOT massage but a
          unique method of using the thumb and fingers on the reflex areas.
          Reflexology is a gentle therapy that encourages the body to work
          naturally to restore its own health by reducing stress and tension
          within the body. When the body is stress free it can start to heal
          itself. Considering that 75% of diseases are stress related, stress
          relief is a vital part of everyday life. You also have over 7200 nerve
          endings in each of your foot, making it one of the most sensitive
          parts of your body. Applying pressure to those nerve endings feels
          fantastic.
        </div>
        <div className='text-black'>How can Reflexology Help You?</div>
        <div>
          The body has the ability to heal itself. Following illness, stress,
          injury or disease, it is in a state of “imbalance” and vital body
          systems can be blocked, preventing the body from functioning
          optimally. Reflexology can be used to restore and maintain the body’s
          natural balance and facilitate healing. For each person, the
          application and the effect of the therapy are unique. As a trained
          Reflexology Therapists I can detect tiny deposits and imbalances and
          by working on these points I can release these blockages bringing the
          body into balance.
        </div>
        <div className='text-black'>Clients report following benefits:</div>

        <div>
          <ul className='list-disc pl-8 space-y-4'>
            <li>Reduced stress, tension and anxiety</li>
            <li>Improved relaxation</li>
            <li>Reduced depression symptoms</li>
            <li>Improved body function </li>
            <li>Regulated sleep patterns and digestive issues</li>
            <li>Relieved joint pain and decreased inflammation</li>
            <li>Improved recovery after injury or surgery</li>
            <li>Increased energy levels</li>
            <li>
              Decreased pain in sciatica, plantar fasciitis, hips, foot and leg
            </li>
            <li>
              Relieved headache/migraine, PMS symptoms, constipation, allergies,
              chronic conditions
            </li>
            <li>Helped ease cancer treatment symptoms</li>
            <li>Improved circulation, drainage and elimination</li>
          </ul>
        </div>
        <hr />
        <div className='text-center w-full text-lg text-black flex flex-col gap-4'>
          Raindrop Therapy Massage
        </div>
        <div className='text-black'>Raindrop Therapy Massage</div>
        <div>
          The Raindrop Massage Technique is a method of using a combination of
          reflexology, aromatherapy, massage techniques and essential oils
          applied on to your spine and feet. High quality essential oils are
          placed on the reflex points on the feet where the spine is
          represented. The Raindrop Massage technique involves dropping the oils
          directly onto the spine from about six inches above the body. The oils
          are then worked into the spine using light strokes with the fingers,
          which stimulate energy impulses and disperse the oils along the
          nervous system throughout the entire body. In this way, the body can
          be brought into balance and the energy centers can be cleared and
          realigned. This method also helps spinal inflammations and to kill
          viruses that hibernate along the spinal column, as well as to help
          straighten any spinal curvatures. The oils will continue to work in
          the body for a week or more following the treatment. 
        </div>
        <div className='text-black'>Oils Used in the Raindrop Technique</div>
        <div className='text-black'>White Angelica</div>
        <div>
          <ul className='list-disc pl-8 space-y-4'>
            <li>
              Blend of 10 different oils (Bergamot, Geranium, Myrrh, Sandalwood,
              Rosewood, Ylang Ylang, Spruce, Hyssop, Melissa, Rose).
            </li>
            <li>
              Increases the aura around the body, bringing a delicate sense of
              strength and protection and a greater awareness of one's
              potential.
            </li>
          </ul>
        </div>
        <div className='text-black'>Valor</div>
        <div>
          <ul className='list-disc pl-8 space-y-4'>
            <li>Balances and aligns electrical energies within the body.</li>
            <li>
              Helps create an environment where structual alignment can occur.
            </li>
          </ul>
        </div>
        <div className='text-black'>Thyme</div>
        <div>
          <ul className='list-disc pl-8 space-y-4'>
            <li>
              Highly antibacterial, anti-fungal, antioxidant, antiviral,
              antiseptic.
            </li>
            <li>
              Supports the immune system by attacking any bacteria, fungus,
              infection, or virus that may be present.
            </li>
            <li>
              Helps one overcome fatigue and physical weakness after an illness.
            </li>
          </ul>
        </div>
        <div className='text-black'>Oregano</div>
        <div>
          <ul className='list-disc pl-8 space-y-4'>
            <li>
              Works with conjunction with thyme to strengthen the immune system
              and to attack bacteria and viruses. 
            </li>
            <li>
              Antiseptic for the respiratory system, help balance metabolism and
              strengthen the vital centers of the body. 
            </li>
          </ul>
        </div>
        <div className='text-black'>Cypress</div>
        <div>
          <ul className='list-disc pl-8 space-y-4'>
            <li>
              Is used for its antibacterial, anti-infectious, antimicrobial and
              diuretic properties.
            </li>
            <li>
              it may function as a decongestant for the circulatory and
              lymphatic system.
            </li>
          </ul>
        </div>
        <div className='text-black'>Wintergreen</div>
        <div>
          <ul className='list-disc pl-8 space-y-4'>
            <li>
              Anti-inflammatory; removes discomfort associated with the
              inflammation of bones, muscles, and joints.
            </li>
            <li>Helps cleanse the lymphatic system. </li>
            <li>
              It contains 99% methyl salicylate, which has a cortisone-like
              action. 
            </li>
            <li>
              It has been helpful in decreasing pain from Arthritis, Tendonitis
              and Rheumatism.
            </li>
          </ul>
        </div>
        <div className='text-black'>Basil</div>
        <div>
          <ul className='list-disc pl-8 space-y-4'>
            <li>
              Antibacterial, anti-infectious, anti-inflammatory, antioxidant,
              antiseptic (stomach/intestinal), antispasmodic (powerful),
              antiviral, decongestant (veins, arteries of the lungs, prostate),
              stimulant (nerves, adrenal glands), and uplifting.
            </li>
          </ul>
        </div>
        <div className='text-black'>Peppermint</div>
        <div>
          <ul className='list-disc pl-8 space-y-4'>
            <li>
              Is used to calm and strengthen the nerves and reduce inflammation.
            </li>
            <li>Improve mental accuracy and soothe the respiratory system.</li>
            <li>Relieve colic, gas, headaches, heartburn, and indigestion.</li>
          </ul>
        </div>
        <div className='text-black'>Marjoram</div>
        <div>
          <ul className='list-disc pl-8 space-y-4'>
            <li>
              Is used to relax spastic muscles, soothe the nerves, relieve
              cramps, aches, and pains.
            </li>
            <li>
              Helps with fluid retention, rheumatism, arthritis, constipation,
              sprains, stiff joints, bruises, obstructions of the liver and
              spleen, respiratory congestion, calms the respiratory system.
            </li>
          </ul>
        </div>
        <div className='text-black'>Ortho Ease</div>
        <div>
          <ul className='list-disc pl-8 space-y-4'>
            <li>
              It is used to help relax all the muscles of the back and legs.
            </li>
            <li>
              Helps to reduce any stress, arthritic pain, or tension that may
              exist
            </li>
          </ul>
        </div>
        <div className='text-black'>Aroma Siez</div>
        <div>
          <ul className='list-disc pl-8 space-y-4'>
            <li>Blend of (Basil, Marjoram, Lavender, Peppermint, Cypress). </li>
            <li>
              Helps to relax, calm, and relieve the tension of spastic muscles
              resulting from sports injury, fatigue, or stress. 
            </li>
          </ul>
        </div>
        <div className='text-black'>Before the Raindrop Therapy Massage</div>
        <div>
          <ul className='list-disc pl-8 space-y-4'>
            <li>Have a cool shower or wash before a massage. </li>
            <li>
              Don't use an underarm deodorant or body spray during the
              treatment, as this will block the effect of  the oils.
            </li>
            <li>
              Don't have a large meal just before the massage as the body's
              systems will have to work too hard at digesting to be thoroughly
              relaxed.
            </li>
            <li>Don't drink alcohol before a treatment.</li>
            <li>
              Don't have a massage if you have a flu or a fever or any serious
              condition. Wait until you are over the worst and then let the
              Raindrop Therapy Massage help restore your system's balance
            </li>
          </ul>
        </div>
        <div className='text-black'>After the Raindrop Therapy Massage</div>
        <div>
          <ul className='list-disc pl-8 space-y-4'>
            <li>Drink a glass of water immediately after the treatment.</li>
            <li>Lie still for at least 5 minutes before getting up.</li>
            <li>
              Don't bathe or shower for at least 12 hours after a treatment to
              allow the oils to be absorbed by the skin and begin the
              all-important work of detoxifying the body.
            </li>
            <li>
              Drink plenty of water for the rest of the day as the kidneys will
              be active in eliminating the toxins.
            </li>
            <li>
              Avoid alcohol for at least 12 hours after the treatment to give
              the body a chance to detoxify thoroughly.
            </li>
          </ul>
        </div>
        <div className='text-black'>
          Raindrop Technique Massage can help to:
        </div>
        <div>
          <ul className='list-disc pl-8 space-y-4'>
            <li>Soothe chronic pain sufferers.</li>
            <li>Aid in back pain and discomforts. </li>
            <li>Facilitate cellular tissue cleansing of toxins. </li>
            <li>Boost the immune system. </li>
            <li>Facilitate trauma and emotional release. </li>
            <li>
              Assist the body in strength, energy and relaxation by helping to
              nurture the spine.
            </li>
            <li>
              Strengthen the body to ward off colds, respiratory issues, VIRUSES
              and influenza. 
            </li>
            <li>Bring balance and deep calm into your system.</li>
            <li>Help to improve bodily functions. </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Services;
