import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import NavBar from './NavBar';
import MobileNavBar from './MobileNavBar';

function Layout() {
  const [isNavBarOpen, setIsNavBarOpen] = useState(false);

  const animation = '-translate-x-3/4 sm:translate-x-0';

  return (
    <div className='flex flex-col  w-full'>
      <div className='flex flex-row justify-between w-full'>
        <NavBar setIsNavBarOpen={setIsNavBarOpen} />
        <div
          className={`flex flex-col bg-black w-full h-full absolute right-0 left-0 top-0 z-0 ease-out duration-700 ${
            (isNavBarOpen && animation) || ''
          }`}
        >
          <MobileNavBar isOpen={isNavBarOpen} handleOnOpen={setIsNavBarOpen} />
          <div className='flex-1 flex bg-white flex-col py-4 sm:pt-24 justify-start items-center sm:items-start mx-auto w-full gap-4'>
            <div className='flex flex-col justify-center items-center h-20 w-full text-lg'>
              Dominika Gulanowska, RCRT
              <div className='text-base my-4'>Contact: 705.344.5792</div>
            </div>
            <div className='px-8'>
              <Outlet />
            </div>
          </div>
          <div className='bg-black text-base py-4 h-28 text-white flex flex-col items-center justify-around text-center'>
            <div className='mb-4'>
              Copyright © 2023 Ontario Reflexology - All Rights Reserved
            </div>
            <div className=''>
              For Website Development Contact: 437-775-5689
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
